const v1Data = [
  {
    title: 'Are you currently pregnant or postpartum?',
    answers: ['Pregnant', 'Postpartum'],
    type: 'multiple-choice',
  },
  {
    title: 'How many weeks pregnant or postpartum are you?',
    type: 'text-field',
  },
  {
    title: 'Is this your first baby?',
    answers: ['Yes', 'No'],
    type: 'multiple-choice',
  },
  {
    title: 'Have you had any complications with your pregnancy or delivery?',
    answers: ['Yes', 'No'],
    type: 'multiple-choice',
    explain: 'If yes, please explain:',
  },
  {
    title: 'On average, how many hours of sleep are you getting per day?',
    type: 'text-field',
  },
  {
    title: 'What are your general thoughts toward mental health and wellness?',
    answers: [
      'I’ll wait out or work through any issues myself.',
      'Haven’t really thought about it.',
      'I’d like to address some problems I’m having.',
      'I’d like to talk through some feelings I’m having.',
      'I’d like to be proactive and get ahead of issues.',
    ],
    type: 'multiple-choice',
  },
  {
    title:
      'How would you describe your mental and emotional health at this moment?',

    answers: ['Sad/Down', 'Anxious', 'Confused', 'Mixed', 'Good'],
    type: 'multiple-choice',
  },
  {
    title:
      'What feeling most describes you when others talk to you about your pregnancy or baby?',
    answers: [
      'Anger, Jealousy or Contempt.',
      'They don’t know what I’m going through.',
      'Indifferent',
      'Externally happy. Internally, confused.',
      'Happy. I’m excited to talk to them.',
    ],
    type: 'multiple-choice',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know? ~80% of new mothers experience negative feelings after giving birth.  If you have or had these feelings, rest assured, you are not alone.',
    path: 'img_1.jpg',
    mobile: 'mobile_1.jpg',
  },
  {
    title: 'Are you currently breastfeeding or plan to breastfeed?',
    answers: ['Yes', 'No'],
    type: 'multiple-choice',
  },
  {
    title:
      'Are you aware of some of the challenges new mothers face with breastfeeding? (e.g., latching, under or over supply)',
    answers: [
      'No, I don’t believe I will be impacted',
      'No, I’ll tackle this later if I need.',
      'No, but would like to learn.',
      'Yes, I feel like I am prepared.',
      'Yes, but would like to learn more.',
    ],
    type: 'multiple-choice',
  },
  {
    title:
      'How aware are you of the impact that nutrition, mental and physical health have on milk supply and breastfeeding?',
    answers: [
      'I don’t believe there is a connection.',
      'Haven’t really thought about it.',
      'I’m knowledgeable in this matter.',
      'I’d like to learn more.',
      'I’d like to optimize all these facets.',
    ],
    type: 'multiple-choice',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know?  Three days after giving birth, more than 90% of moms report problems breastfeeing.  These issues can be addressed with the right guidance.',
    path: 'img_2.jpg',
    mobile: 'mobile_2.jpg',
  },
  {
    title: 'How would you describe your daily schedule?',
    answers: [
      'Flexible',
      'Somewhat Flexible',
      'Mixed',
      'Somewhat Structured',
      'Structured',
    ],
    type: 'text-slider',
  },
  {
    title: 'Do you plan to sleep train your child?',
    answers: [
      'I don’t think I will need to.',
      'I’ll tackle this later',
      'Undecided, would like to learn more.',
      'Yes, I’ve already started.',
      'Yes, I plan to.',
    ],
    type: 'multiple-choice',
  },
  {
    title:
      'How familiar are you with the latest safety recommendations as it relates to a child’s sleep environment?',
    answers: [
      'Not at all familiar',
      'Somewhat familiar',
      'Familiar',
      'Very familiar',
      'Extremely familiar',
    ],
    type: 'text-slider',
  },
  {
    title:
      'How familiar are you with colic, digestive issues, and/or common habits impacting sleep patterns?',
    answers: [
      'Not at all familiar',
      'Somewhat familiar',
      'Familiar',
      'Very familiar',
      'Extremely familiar',
    ],
    type: 'text-slider',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know? Up to 25% of babies between two weeks and three months of age develop colic. There are proven techniques to maintain predictable sleep patters for babies.',
    path: 'img_3.png',
    mobile: 'mobile_3.jpg',
  },
  {
    title:
      'How would you describe your nutritional habits at this point in time?',
    answers: [
      'Not very good',
      'Not good',
      'Decent',
      'Pretty good',
      'Very good',
    ],
    type: 'text-slider',
  },
  {
    title:
      'How aware are you of the impact that nutrition can have on  your pregnancy, delivery, and postpartum health?',
    answers: [
      'Not aware at all',
      'Not very aware',
      'Somewhat aware',
      'Aware',
      'Very aware',
    ],
    type: 'text-slider',
  },
  {
    title:
      'At this time, what is the priority of your health, exercise and nutrition?',
    answers: [
      'Low priority',
      'Somewhat low priority',
      'Priority',
      'Somewhat high priority',
      'High priority',
    ],
    type: 'text-slider',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know? Gestational diabetes impacts nearly 10% of pregnant women in the United States. Good nutrition factors into your wellness during and after your pregnancy.',
    path: 'img_4.png',
    mobile: 'mobile_4.jpg',
  },
  {
    title: 'Are you having any chronic physical pain?',
    answers: ['Yes', 'No'],
    type: 'multiple-choice',
    explain: 'If yes, please describe:',
  },
  {
    title:
      'How familiar are you with urinary incontinence, diastasis recti, and pelvic floor conditioning?',
    answers: [
      'Not at all familiar',
      'Somewhat familiar',
      'Familiar',
      'Very familiar',
      'Extremely familiar',
    ],
    type: 'text-slider',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know? More than 60% of pregnant women experience Diastasis Recti (abdominal muscles separation). Common exercises may do more harm than good.',
    path: 'img_5.png',
    mobile: 'mobile_5.jpg',
  },
  {
    title: 'How would you describe your relationship with your partner?',
    answers: [
      'Not in a relationship at this time.',
      'Arguing all the time.',
      'Not important to me at the time.',
      'Mostly good with some struggles.',
      'Supporting and fulfilling.',
    ],
    type: 'multiple-choice',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know? Roughly 2/3 of couples say their relationship deteriorates after having a baby. Now is the time to be proactive.',
    path: 'img_6.png',
    mobile: 'mobile_6.jpg',
  },
  {
    title: 'What phrase do you most identify with?',
    answers: [
      'My baby is my only focus at this time.',
      'My baby is my main focus at this time.',
      'I would like to focus on my career as well as motherhood.',
      'I would like to find time to maintain friendships and relationships.',
      'I would like to balance family, friendships, career and interests as much as possible.',
    ],
    type: 'multiple-choice',
  },
  {
    title: 'You’ve completed your questionnaire!',
    caption:
      'Register below to see our suggestions and get your personalized action plan.',
    disclaimer: 'MaternalWell does not share any personal information.',
    checkbox: 'I would like to receive a follow-up with a care coordinator.',
    optional:
      'Please let us know what you need help with or any topics you would like to discuss. (Optional)',
    type: 'text-field-result',
  },
]

const v2Data = [
  {
    title: 'Are you currently pregnant or postpartum?',
    answers: ['Pregnant', 'Postpartum'],
    type: 'multiple-choice',
  },
  {
    title: 'How many weeks pregnant or postpartum are you?',
    type: 'text-field',
  },
  {
    title: 'Is this your first baby?',
    answers: ['Yes', 'No'],
    type: 'multiple-choice',
  },
  {
    title: 'Have you had any complications with your pregnancy or delivery?',
    answers: ['Yes', 'No'],
    type: 'multiple-choice',
    explain: 'If yes, please explain:',
  },
  {
    title: 'On average, how many hours of sleep are you getting per day?',
    type: 'text-field',
  },
  {
    title: 'What are your general thoughts toward mental health and wellness?',
    answers: [
      'I’ll wait out or work through any issues myself.',
      'Haven’t really thought about it.',
      'I’d like to address some problems I’m having.',
      'I’d like to talk through some feelings I’m having.',
      'I’d like to be proactive and get ahead of issues.',
    ],
    type: 'multiple-choice',
  },
  {
    title:
      'How would you describe your mental and emotional health at this moment?',

    answers: ['Sad/Down', 'Anxious', 'Confused', 'Mixed', 'Good'],
    type: 'multiple-choice',
  },
  {
    title:
      'What feeling most describes you when others talk to you about your pregnancy or baby?',
    answers: [
      'Anger, Jealousy or Contempt.',
      'They don’t know what I’m going through.',
      'Indifferent',
      'Externally happy. Internally, confused.',
      'Happy. I’m excited to talk to them.',
    ],
    type: 'multiple-choice',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know? ~80% of new mothers experience negative feelings after giving birth.  If you have or had these feelings, rest assured, you are not alone.',
    path: 'img_1.jpg',
    mobile: 'mobile_1.jpg',
  },
  {
    title: 'Are you currently breastfeeding or plan to breastfeed?',
    answers: ['Yes', 'No'],
    type: 'multiple-choice',
  },
  {
    title:
      'Are you aware of some of the challenges new mothers face with breastfeeding? (e.g., latching, under or over supply)',
    answers: [
      'No, I don’t believe I will be impacted',
      'No, I’ll tackle this later if I need.',
      'No, but would like to learn.',
      'Yes, I feel like I am prepared.',
      'Yes, but would like to learn more.',
    ],
    type: 'multiple-choice',
  },
  {
    title:
      'How aware are you of the impact that nutrition, mental and physical health have on milk supply and breastfeeding?',
    answers: [
      'I don’t believe there is a connection.',
      'Haven’t really thought about it.',
      'I’m knowledgeable in this matter.',
      'I’d like to learn more.',
      'I’d like to optimize all these facets.',
    ],
    type: 'multiple-choice',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know?  Three days after giving birth, more than 90% of moms report problems breastfeeing.  These issues can be addressed with the right guidance.',
    path: 'img_2.jpg',
    mobile: 'mobile_2.jpg',
  },
  {
    title: 'How would you describe your daily schedule?',
    answers: [
      'Flexible',
      'Somewhat Flexible',
      'Mixed',
      'Somewhat Structured',
      'Structured',
    ],
    type: 'text-slider',
  },
  {
    title: 'Do you plan to sleep train your child?',
    answers: [
      'I don’t think I will need to.',
      'I’ll tackle this later',
      'Undecided, would like to learn more.',
      'Yes, I’ve already started.',
      'Yes, I plan to.',
    ],
    type: 'multiple-choice',
  },
  {
    title:
      'How familiar are you with the latest safety recommendations as it relates to a child’s sleep environment?',
    answers: [
      'Not at all familiar',
      'Somewhat familiar',
      'Familiar',
      'Very familiar',
      'Extremely familiar',
    ],
    type: 'text-slider',
  },
  {
    title:
      'How familiar are you with colic, digestive issues, and/or common habits impacting sleep patterns?',
    answers: [
      'Not at all familiar',
      'Somewhat familiar',
      'Familiar',
      'Very familiar',
      'Extremely familiar',
    ],
    type: 'text-slider',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know? Up to 25% of babies between two weeks and three months of age develop colic. There are proven techniques to maintain predictable sleep patters for babies.',
    path: 'img_3.png',
    mobile: 'mobile_3.jpg',
  },
  {
    title:
      'How would you describe your nutritional habits at this point in time?',
    answers: [
      'Not very good',
      'Not good',
      'Decent',
      'Pretty good',
      'Very good',
    ],
    type: 'text-slider',
  },
  {
    title:
      'How aware are you of the impact that nutrition can have on  your pregnancy, delivery, and postpartum health?',
    answers: [
      'Not aware at all',
      'Not very aware',
      'Somewhat aware',
      'Aware',
      'Very aware',
    ],
    type: 'text-slider',
  },
  {
    title:
      'At this time, what is the priority of your health, exercise and nutrition?',
    answers: [
      'Low priority',
      'Somewhat low priority',
      'Priority',
      'Somewhat high priority',
      'High priority',
    ],
    type: 'text-slider',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know? Gestational diabetes impacts nearly 10% of pregnant women in the United States. Good nutrition factors into your wellness during and after your pregnancy.',
    path: 'img_4.png',
    mobile: 'mobile_4.jpg',
  },
  {
    title: 'Are you having any chronic physical pain?',
    answers: ['Yes', 'No'],
    type: 'multiple-choice',
    explain: 'If yes, please describe:',
  },
  {
    title:
      'How familiar are you with urinary incontinence, diastasis recti, and pelvic floor conditioning?',
    answers: [
      'Not at all familiar',
      'Somewhat familiar',
      'Familiar',
      'Very familiar',
      'Extremely familiar',
    ],
    type: 'text-slider',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know? More than 60% of pregnant women experience Diastasis Recti (abdominal muscles separation). Common exercises may do more harm than good.',
    path: 'img_5.png',
    mobile: 'mobile_5.jpg',
  },
  {
    title: 'How would you describe your relationship with your partner?',
    answers: [
      'Not in a relationship at this time.',
      'Arguing all the time.',
      'Not important to me at the time.',
      'Mostly good with some struggles.',
      'Supporting and fulfilling.',
    ],
    type: 'multiple-choice',
  },
  {
    title: '',
    type: 'image',
    alt: 'Did you know? Roughly 2/3 of couples say their relationship deteriorates after having a baby. Now is the time to be proactive.',
    path: 'img_6.png',
    mobile: 'mobile_6.jpg',
  },
  {
    title: 'What phrase do you most identify with?',
    answers: [
      'My baby is my only focus at this time.',
      'My baby is my main focus at this time.',
      'I would like to focus on my career as well as motherhood.',
      'I would like to find time to maintain friendships and relationships.',
      'I would like to balance family, friendships, career and interests as much as possible.',
    ],
    type: 'multiple-choice',
  },
  {
    title: 'You’ve completed your questionnaire!',
    caption:
      'Register below to see our suggestions and get your personalized action plan.',
    disclaimer: 'MaternalWell does not share any personal information.',
    checkbox: 'I would like to receive a follow-up with a care coordinator.',
    optional:
      'Please let us know what you need help with or any topics you would like to discuss. (Optional)',
    type: 'text-field-result',
  },
]

const questionData = {
  v1: v1Data,
  v2: v2Data,
}

export default questionData;
