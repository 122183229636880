import React from 'react'

const TextField = ({ index, update }) => (
  <div>
    <label htmlFor={`question_${index}`}></label>
    <input
      type="text"
      id={`question_${index}`}
      name={`question_${index}`}
      required
      onChange={(e) => {
        update(index, e.target.value)
      }}
      minLength="0"
      maxLength="50"
    ></input>
  </div>
)

export default TextField
