import React, { useState, useEffect } from 'react'

const TextSlider = ({ index, question, update }) => {
  const [value, setValue] = useState(1)

  const textFor = (value) => {
    return question.answers[value - 1]
  }

  const updateValue = (event) => {
    setValue(event.target.value)
    update(index, event.target.value)
  }

  useEffect(() => {
    if (update) {
      update(index, value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div className="text-slider">
      <label htmlFor="min">{question.answers[0]}</label>
      <label htmlFor="max">{textFor(question.answers.length)}</label>
      <input
        id={`answer_${index}`}
        defaultValue="1"
        max={question.answers.length}
        min="1"
        onInput={updateValue}
        type="range"
      ></input>
      <label id={`answer_${index}`}>{textFor(value)}</label>
    </div>
  )
}

export default TextSlider
