const reviews = [
  {
    name: 'Isabel D.',
    text: 'This membership program is so educational and easy to digest. Just what I needed during this challenging time. It has been such an inspiration.',
  },
  {
    name: 'Nicole W.',
    text: 'The fact that mothers who have overcome their struggles take time out of their busy schedule to ‘pay it forward’ by encouraging women on peer sessions is a testament to how valuable they are.'
  },
  {
    name: 'Elle J.',
    text: 'My MaternalWell care coordinator is super helpful, and her check-ins make me feel like I always have someone to turn to.'
  },
  {
    name: 'Michelle P.',
    text: 'I joined the peer group sessions, and they were great! Very refreshing to hear from other moms and their stories. They helped me realize that my struggles were common for many new moms.'
  },
  {
    name: 'Pratisa V.',
    text: 'My MaternalWell lactation consultant has been nothing but informative, empathic, and encouraging during my virtual visits with her. She was thorough in evaluating my situation and coming up with a plan on how to help me and my baby be comfortable during feedings.'
  },
  {
    name: 'Jane B.',
    text: 'The peer group session was so comforting because I was able to share my struggles with other women who have had similar experiences. It felt nice to finally get to express my feelings without any judgement and learn new ways to better cope during the tough times.'
  },
  {
    name: 'Vanessa E.',
    text: 'I absolutely love this membership program. The daily reads are quick and easy to navigate. And they provide so much insight. Thank you, MaternalWell.'
  },
];

export default reviews;
