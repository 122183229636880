const serviceBullets = {
  PP: {
    'Mental Health': [
      'Most moms get postpartum blues. Being proactive and aware of your mental wellness can normalize the postpartum blues and help prevent or lessen the progression of symptoms. Journaling your feelings after delivery can help you be more self-aware.',
    ],
    'Breastfeeding/Lactation': [
      'Over 90% of women experience some difficulty in breastfeeding (including latching issues, nipple problems, too much milk, too little milk).  Working with a lactation consultant doubles your chance of successful breastfeeding to at least 6 months!',
    ],
    'Infant Sleep Coaching': [
      'Finding the ideal way to make your baby sleep is a process of trial and error. Follow a consistent timetable and use calming techniques to make the baby sleep faster. Be adaptable and receptive to your kid\'s evolving needs, and don\'t overthink it. Using a sleep coach can help.',
    ],
    Nutrition: [
      'Omega-3 fatty acids have one of the strongest links to depression and anxiety in the postpartum period. You can get naturally Omega-3 fatty acids in fish and seafood, nuts and seeds and legumes (flaxseed, walnuts, chia seeds, kidney beans, soybean), plant oils (Canola oil, soybean oil), veggie/ fruits (broccoli, cantaloupe, spinach, brussel sprouts).'
    ],
    'Physical Therapy': ['Although abdominal wall separation is common (60% of moms!), most women have never been checked for this or aware of this condition.  Be proactive and address this problem within the first three months postpartum to avoid long term changes in posture, pelvic floor dysfunction, and low back pain. Doing the correct exercise is key (no sit-ups!).'],
    'Couples Wellness': [
      'You are not a relationship failure if you encounter some challenges. Remember most couples go through this. Be proactive in safeguarding your relationship by carving out time for each other and by learning to be an active listener for each other.',
    ],
    'Lifestyle Coaching': [
      'Going back to work seems overwhelming. Learn to set your daily, weekly, and monthly schedule/goals. Taking some time to put “pen to paper” will go a long way in. This will give you a sense of control and help to decrease stress.',
    ],
  },
  PG: {
    'Mental Health': [
      'Counseling is not just for people who have anxiety or depression! If you have risk factors, starting cognitive behavioral therapy before you have symptoms can decrease your risk for postpartum depression!  Know your risk factors and seek support.',
    ],
    'Breastfeeding/Lactation': [
      'Over 90% of women experience some difficulty in breastfeeding.  Be ready before your baby arrives with the tools that can help you succeed in breastfeeding (lactation counselor, breast pump, supportive pillows)!',
    ],
    'Infant Sleep Coaching': [
      'Finding the ideal way to help your baby sleep is a process of trial and error. Follow a consistent timetable and use calming techniques to make the baby sleep faster.  Be adaptable and receptive to your kid\'s evolving needs, and don\'t overthink it. Using a sleep coach can help.',
    ],
    Nutrition: [
      'Omega-3 fatty acids like DHA are important in your baby’s brain and eyes development. It also benefits moms\' brain function and can decrease depression during and after birth. Make sure you eat foods rich in these helpful fatty acids (fish, nuts, seeds, legumes are some examples!).',
    ],
    'Physical Therapy': ['Around 60% of women report urinary leakage during pregnancy due to weakened pelvic floor muscles that may be unable to squeeze effectively to keep urine in.  Do Kegel exercises to strengthen these muscles even before you have symptoms!'],
    'Couples Wellness': [
      'Pregnancy and postpartum can be tough on a relationship, so it\'s essential to do what you can to encourage open communication and mutual support. Working with a marriage counselor can help you with expressing your needs and feelings in a productive way and give you tools to help with the stressful postpartum time.',
    ],
    'Lifestyle Coaching': [
      'Learn to say no. Avoid overcommitment and learn to listen to your body throughout your pregnancy.',
    ],
  },
}

export default serviceBullets
