const memberships = {
  id: "premium-membership",
  description: "<p><strong class=\"bold\">You did it! You took the first step toward maternal wellness.</strong></p><p>Based on your assessment, we suggest taking advantage of our<br/> <strong>Monthly Membership (<i>Maternal Health Month Special 30-day free trial</i>).</strong></p><p>This program will:<ul><li>Empower you with information you need through interactive content.</li><li>Support you with our peer group sessions and our 24-hour text service.</li><li>Provide access to our specialists with <strong><i>30% discounts</i></strong> for private telehealth sessions.</li></ul></p>",
  title: 'Premium Membership',
  top: '**14-DAY FREE TRIAL**',
  period: 'month',
  items: [
    {
      label: 'MaternalWell Program',
      items: ['Quick & easy daily content', 'Guided self-help']
    },
    {
      label: 'MaternalWell Telehealth',
      items: ['Vetted pregnancy/postpartum specialists', 'On demand scheduling', '30% discount on 1-on-1 sessions', 'Group coaching sessions w/ specialists']
    },
    {
      label: 'MaternalWell Community',
      items: ['Private community of new/expecting moms', 'Two peer group sessions/month']
    },
    {
      label: 'Dedicated Care Coordinator',
      items: []
    },
    {
      label: 'Unlimited text support',
      items: []
    },
    {
      label: '$35/month after trial',
      items: []
    },
    {
      label: 'Cancel anytime',
      items: []
    }
  ],
  link: 'https://emr.maternalwell.com/?price=membership',
  // link: 'http://mw-dev-api.herokuapp.com/?price=membership',
  cta: 'Start free trial',
};

const freeplan = {
  id: "free-membership",
  description: "<p><strong class=\"bold\">You did it! You took the first step toward maternal wellness.</strong></p><p>Based on your assessment, we suggest taking advantage of our<br/> <strong>Monthly Membership (<i>Maternal Health Month Special 30-day free trial</i>).</strong></p><p>This program will:<ul><li>Empower you with information you need through interactive content.</li><li>Support you with our peer group sessions and our 24-hour text service.</li><li>Provide access to our specialists with <strong><i>30% discounts</i></strong> for private telehealth sessions.</li></ul></p>",
  title: 'Intro to Wellness',
  top: '**FREE**',
  period: 'month',
  items: [
    {
      label: 'MaternalWell Program',
      items: ['Quick & easy daily content', 'Guided self-help']
    },
    {
      label: 'MaternalWell Telehealth',
      items: ['Vetted pregnancy/postpartum specialists', 'On demand scheduling']
    },
    {
      label: 'MaternalWell Community',
      items: ['Private community of new/expecting moms']
    }
  ],
  link: 'https://emr.maternalwell.com/?price=free',
  // link: 'http://mw-dev-api.herokuapp.com/?price=free',
  cta: 'Sign up now',
};

export {
  freeplan,
  memberships
}
