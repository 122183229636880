import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { Quiz, StartTrial, AssessmentResult } from './pages'
import logo from './logo@2x.png'
import './pages/style.scss'
import ResultV2 from './pages/resultv2'

const tagManagerArgs = {
  gtmId: 'GTM-KX7BCLC',
}

const baseRoute = process.env.PUBLIC_URL

TagManager.initialize(tagManagerArgs)

const App = () => (
  <Router basename={baseRoute}>
    <div>
      <img src={logo} className="app-logo" alt="logo" />
      <Switch>
        <Route path="/result">
          <AssessmentResult />
        </Route>
        <Route path="/result-v2">
          <ResultV2 />
        </Route>
        <Route path="/result-v3">
          <ResultV2 />
        </Route>
        <Route path="/start-free-trial">
          <StartTrial />
        </Route>
        <Route path="/maternalcare">
          <Quiz version={2} />
        </Route>
        <Route path="/v3">
          <Quiz version={3} />
        </Route>
        <Route path="/">
          <Quiz />
        </Route>
      </Switch>
    </div>
  </Router>
)

export default App
