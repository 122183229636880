import React from 'react'
import AssessmentImage from '../../../helpers/assessment_image'

const Image = ({ question }) => (
  <div>
    <label htmlFor="image"></label>
    <img
      className="slide-image desktop"
      alt={question.alt}
      src={AssessmentImage(question.path)}
    />
    <img
      className="slide-image mobile"
      alt={question.alt}
      src={AssessmentImage(question.mobile)}
    />
  </div>
)

export default Image
