import React from 'react'

const PriceList = ({ selected = false, content }) => (
  <div className={selected ? 'price-list active' : 'price-list'}>
    <div className="price-list__header">
      <h4 className="price-list__title">{content.title}</h4>
      <h3 className="price-list__amount">{content.top}</h3>
    </div>
    <div className="price-list__content">
      <ul className="price-list__items">
        {content.items.map((item, key) => (
          <li key={key} className="price-list__item">
            {item.label}
            {item.items.length > 0 && (
              <ul>
                {item.items.map((subItem, index) => (
                  <li key={index}>{subItem}</li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
    <div className="price-list__action">
      <a className="button price-list__btn" href={content.link}>
        {content.cta}
      </a>
    </div>
  </div>
)

export default PriceList
