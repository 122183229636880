import React, { useState, useEffect, useRef } from 'react'

const sanitize = (phrase) => {
  return phrase.replace(/\W/gi, '-').toLowerCase()
}

const toId = (index, answer) => {
  return `answer_${index}_${sanitize(answer)}`
}

const MultipleChoice = ({ index, question, update }) => {
  const containerRef = useRef(null)
  const [explanation, setExplanation] = useState('')
  const [answer, setAnswer] = useState('')
  const [isClicked, setIsClicked] = useState(false)

  const onCheck = (event, answer) => {
    if (event.target.value === 'Yes' && (index === 3 || index === 23)) {
      setIsClicked(true)
    } else {
      setIsClicked(false)
    }
    setAnswer(answer)
  }

  useEffect(() => {
    if (update) {
      update(index, answer + explanation)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked, answer, explanation])

  return (
    <div>
      <div className="multiple-choice form-required" ref={containerRef}>
        {question.answers.map((answer, key) => (
          <div className="multiple-choice--inputs" key={key}>
            <input
              id={toId(index, answer)}
              name={`answer_${index}`}
              type="radio"
              value={answer}
              onClick={(e) => {
                onCheck(e, answer)
              }}
            />
            <label htmlFor={toId(index, answer)}>{answer}</label>
          </div>
        ))}
        {isClicked ? (
          <div className="yes-explain">
            {question.explain}
            <input
              className="explain-input"
              type="text"
              id={`question_${index}`}
              name={`question_${index}`}
              onChange={(e) => {
                setExplanation('\nexplanation: ' + e.target.value)
              }}
              required
              minLength="0"
              maxLength="200"
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  )
}

export default MultipleChoice
