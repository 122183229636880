import { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Swiper from 'swiper/bundle'
import questionData from '../data/question_data'
import serviceBullets from '../data/services_data'

const useApp = (version = 1) => {
  const history = useHistory()
  const questions = questionData[`v${version}`]
  const [submitted, setSubmitted] = useState(false)
  const [assessmentId, setAssessmentId] = useState(null)
  const [swiper, setSwiper] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [data, setData] = useState(
    questions.map((question) => ({
      title: question.title,
      response: question.type === 'text-slider' ? '1' : undefined,
    }))
  )
  const [register, setRegister] = useState({})

  const allowNext = () => {
    const activeSlide = swiper.slides[swiper.activeIndex]

    switch (questions[swiper.activeIndex].type) {
      case 'image':
        return true
      case 'multiple-choice':
        return [...activeSlide.querySelectorAll("input[type='radio']")].some(
          (input) => {
            if (input.checked && input.value === 'Yes') {
              const input = activeSlide.querySelector("input[type='text']")
              if (input) {
                return input.checkValidity()
              }
              return true
            }
            return input.checked
          }
        )
      default:
        return [...activeSlide.querySelectorAll('input')].every((input) => {
          return input.checkValidity()
        })
    }
  }

  const prevSlide = () => {
    if (swiper) {
      postData()
      swiper.slidePrev()
    }
  }

  const nextSlide = () => {
    if (allowNext()) {
      postData()
      swiper.slideNext()
    } else {
      alert('Please fill out the form to continue.')
    }
  }

  const postData = () => {
    if (assessmentId) {
      axios
        .put(`${window.server_url}/assessment/${assessmentId}`, {
          assessment: data,
        })
        .then((res) => {
          if (res.data && res.data.assessment_id) {
            setAssessmentId(res.data.assessment_id)
          }
        })
    } else {
      axios
        .post(`${window.server_url}/assessment`, {
          assessment: data,
        })
        .then((res) => {
          if (res.data && res.data.assessment_id) {
            setAssessmentId(res.data.assessment_id)
          }
        })
    }
  }

  const onUpdate = (index, value) => {
    const newData = [...data]
    if (index === activeIndex) {
      newData[index].response = value
      setData(newData)
    } else if (
      ['first_name', 'last_name', 'email', 'password'].includes(index)
    ) {
      setRegister({
        ...register,
        [index]: value,
      })
    }
  }

  const onResize = () => swiper.updateSize()

  const onSubmit = () => {
    if (!allowNext()) {
      alert('Please fill out the form to continue.')
      return
    }
    setSubmitted(true)
    let type = 'pregnant'
    if (data[0].response === 'Postpartum') {
      type = 'postpartum'
    }

    // const email = data[data.length - 1].response

    Promise.allSettled([
      axios.put(`${window.server_url}/assessment/${assessmentId}`, {
        assessment: data,
        email: register.email,
      }),
      axios.post(`${window.server_url}/quick-register/`, {
        ...register,
        type: type === 'pregnant' ? 'PG' : 'PP',
      }),
      axios.post(`${window.server_url}/assessment/${assessmentId}/pdf`, {
        email: register.email,
      }),
    ]).then((res) => {
      history.push(
        `/result${
          version === 1 ? '' : `-v${version}`
        }?assessment_id=${assessmentId}&type=${type}`
      )
    })
  }

  useEffect(() => {
    const handle = new Swiper('.swiper-container', {
      speed: 200,
      allowTouchMove: false,
      simulateTouch: false,
      autoHeight: false,

      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
      },

      on: {
        init: () => {
          // dev plug - allows to go direct to slide
          //swiper.slideTo(3)
        },
        slideChange: (swiper) => {
          setActiveIndex(swiper.activeIndex)
        },
      },
    })
    setSwiper(handle)
  }, [])

  return {
    questionData: questions,
    submitted,
    activeIndex,
    prevSlide,
    nextSlide,
    onUpdate,
    onResize,
    onSubmit,
  }
}

export const useAssessment = (assessmentId, type) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [score, setScore] = useState([])
  const [step, setStep] = useState(0)
  const [showMore, setShowMore] = useState(false)
  const assessType = type === 'pregnant' ? 'Pregnant' : 'Postpartum'

  const mwScore =
    score &&
    Number(
      (score.reduce((prev, cur) => +prev + +cur.score, 0) * 6) / 5
    ).toFixed(0)

  const mwAnswer = useMemo(() => {
    return score.map((item) => item.index)
  }, [score])

  const mwStage = useMemo(() => {
    if (mwScore) {
      if (mwScore <= 76) {
        return 'Recognition'
      }
      if (mwScore > 76 && mwScore <= 80) {
        return 'Reflection'
      }
      if (mwScore > 80 && mwScore <= 85) {
        return 'Inclination'
      }
      if (mwScore > 85 && mwScore <= 95) {
        return 'Initiative'
      }
      return 'Continuity'
    }
    return ''
  }, [mwScore])

  const mwPercent = useMemo(() => {
    if (mwScore) {
      if (mwScore <= 76) {
        return 15
      }
      if (mwScore > 76 && mwScore <= 80) {
        return 20
      }
      if (mwScore > 80 && mwScore <= 85) {
        return 40
      }
      if (mwScore > 85 && mwScore <= 95) {
        return 20
      }
      return 5
    }
    return ''
  }, [mwScore])

  /* const answerPercent = useMemo(() => {
    if (score && data) {
      return score.map((item) => {
        const index = item.index
        const qIndex = item.qIndex
        if (index === 0) return data[0][qIndex] / 2
        if (index === 1) return data[0][qIndex] + data[1][qIndex] / 2
        if (index === 2)
          return data[0][qIndex] + data[1][qIndex] + data[2][qIndex] / 2
        if (index === 3)
          return (
            data[0][qIndex] +
            data[1][qIndex] +
            data[2][qIndex] +
            data[3][qIndex] / 2
          )
        return (
          data[0][qIndex] +
          data[1][qIndex] +
          data[2][qIndex] +
          data[3][qIndex] +
          data[4][qIndex] / 2
        )
      })
    }
    return []
  }, [data, score]) */

  const postpartumOption = useMemo(() => {
    const mapScore = mwScore > 75 ? mwScore : 75
    return {
      title: {
        text: `MAP Score`,
      },
      xAxis: {
        categories: ['Score'],
        title: {
          text: '',
        },
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        min: 0,
        tickInterval: 10,
        title: {
          text: '',
        },
      },
      plotOptions: {
        column: {
          stacking: 'percent',
        },
      },
      series: [
        {
          type: 'column',
          name: 'Total',
          data: [100 - mapScore],
          color: {
            pattern: {
              path: {
                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                strokeWidth: 3,
              },
              width: 5,
              height: 5,
              opacity: 0.6,
            },
          },
        },
        {
          type: 'column',
          name: 'Your Score',
          data: [+mapScore],
          color: 'purple',
        },
      ],
    }
  }, [mwScore])

  const postpartumPercentOption = useMemo(() => {
    const mapScore = mwScore > 75 ? mwScore : 75
    return {
      title: {
        text: null,
      },
      xAxis: {
        categories: ['Percent'],
        title: {
          text: '',
        },
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        min: 0,
        tickInterval: 10,
        title: {
          text: '',
        },
        labels: {
          formatter: function() {
            const label = this.axis.defaultLabelFormatter.call(this);
            return `${label}%`
          }
        }
      },
      plotOptions: {
        column: {
          stacking: 'percent',
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: 'Total',
          data: [100 - mapScore],
          color: {
            pattern: {
              path: {
                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                strokeWidth: 3,
              },
              width: 5,
              height: 5,
              opacity: 0.6,
            },
          },
        },
        {
          type: 'column',
          name: 'Your Percent',
          data: [+mapScore],
          color: 'purple',
        },
      ],
    }
  }, [mwScore])

  const respondentsPieOption = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: 'MAP Score (All Respondents)',
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    legend: {
      title: {
        text: 'Score Range',
      },
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemMarginTop: 10,
      itemMarginBottom: 10,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.0f} %',
          distance: -30,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: 'All Respondents',
        data: [
          {
            name: '25-40',
            y: 15,
          },
          {
            name: '41-55',
            y: 20,
          },
          {
            name: '56-70',
            y: 40,
          },
          {
            name: '71-85',
            y: 20,
          },
          {
            name: '86-100',
            y: 5,
          },
        ],
      },
    ],
  }

  const services = useMemo(() => {
    const items = []
    if (
      [2, 3, 4].includes(mwAnswer[0]) ||
      [0, 1, 2, 3].includes(mwAnswer[1]) ||
      [0, 1, 3].includes(mwAnswer[2])
    ) {
      items.push('Mental Health')
    }
    if (
      mwAnswer[3] === 0 ||
      [2, 4].includes(mwAnswer[4]) ||
      [3, 4].includes(mwAnswer[5])
    ) {
      items.push('Breastfeeding/Lactation')
    }
    if ([2, 3, 4].includes(mwAnswer[7])) {
      items.push('Infant Sleep Coaching')
    }
    if ([0, 1].includes(mwAnswer[11])) {
      items.push('Nutrition')
    }
    if (mwAnswer[12] === 0 || mwAnswer[13] === 0) {
      items.push('Physical Therapy')
    }
    if (mwAnswer[14] === 1) {
      items.push('Couples Wellness')
    }
    if (mwAnswer[15] === 4) {
      items.push('Lifestyle Coaching')
    }
    return items
  }, [mwAnswer])

  const bullets = useMemo(() => {
    const isPP = assessType === 'Postpartum'
    const _services = isPP ? serviceBullets.PP : serviceBullets.PG
    let _bullets = []
    services.forEach(
      (service) => (_bullets = [..._bullets, ..._services[service]])
    )
    return _bullets
  }, [assessType, services])

  const onNext = (e) => {
    e.preventDefault()
    setStep((prev) => prev + 1)
    window.scrollTo(0, 0)
  }

  const onBack = (e) => {
    e.preventDefault()
    setStep((prev) => prev - 1)
    window.scrollTo(0, 0)
  }

  const onComplete = () => {
    window.location.href = 'https://portal.maternalwell.com/login'
  }

  const onLearnMore = (e) => {
    e.preventDefault()
    setShowMore(true)
  }

  const onCloseLearnMore = () => setShowMore(false)

  useEffect(() => {
    ;(async () => {
      const resData = await axios.get(
        `${window.server_url}/assessment/summary?type=${type}`
      )
      setData(resData.data)
      const scoreData = await axios.get(
        `${window.server_url}/assessment/summary/${assessmentId}`
      )
      setScore(scoreData.data)
      setTimeout(() => setLoading(false), 1000)
    })()
  }, [assessmentId, type])

  return {
    loading,
    step,
    data,
    services,
    bullets,
    score,
    mwScore: mwScore > 75 ? mwScore : 75,
    mwAnswer,
    mwStage,
    mwPercent: mwPercent > 75 ? mwPercent : 75,
    assessType: assessType.toLowerCase(),
    postpartumOption,
    postpartumPercentOption,
    respondentsPieOption,
    showMore,
    onNext,
    onBack,
    onComplete,
    onLearnMore,
    onCloseLearnMore,
  }
}

export default useApp
