import React from 'react'

const TextFieldResult = ({ question, index, update }) => (
  <div>
    <div className="caption">{question.caption}</div>
    <label htmlFor={`question_${index}`}></label>
    <div className="form-group">
      <input
        type="text"
        placeholder="First Name"
        id="first_name"
        name="first_name"
        onChange={(e) => {
          update("first_name", e.target.value)
        }}
        required
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        placeholder="Last Name"
        id="last_name"
        name="last_name"
        onChange={(e) => {
          update("last_name", e.target.value)
        }}
      />
    </div>
    <div className="form-group">
      <input
        type="email"
        placeholder="Email"
        id="email"
        name="email"
        onChange={(e) => {
          update("email", e.target.value)
        }}
        required
      />
    </div>
    <div className="form-group">
      <input
        type="password"
        placeholder="Password"
        id="password"
        name="password"
        onChange={(e) => {
          update("password", e.target.value)
        }}
        required
      />
    </div>
    {/* <label className="checkbox">
      <input
        type="checkbox"
        id={`checkbox_${index}`}
        name="contact"
        onChange={(e) => update('contact', e.target.checked)}
      />
      {question.checkbox}
    </label> */}
    {/* <div className="disclaimer">{question.optional}</div>
    <textarea
      className="comment"
      name="comment"
      onChange={(e) => update('comment', e.target.value)}
    ></textarea> */}
  </div>
)

export default TextFieldResult
