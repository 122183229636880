import React, { useEffect } from 'react'
import PriceList from '../components/PriceList'
import { memberships, freeplan } from '../data/membershipData'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const StartTrial = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="container">
      <div className="result-page">
        <h3>
          <i>Join today for free with the plan that works for you!</i>
        </h3>
      </div>
      <div className="result-page">
        <div className="result-price-list">
          <PriceList content={freeplan} />
        </div>
        <div className="result-price-list">
          <PriceList content={memberships} />
        </div>
      </div>
    </div>
  )
}

export default StartTrial
