import React from 'react'
import Slide from '../components/slide'
import useApp from './hooks'
import 'swiper/swiper-bundle.css'

import spinner from '../loading.gif'

const Quiz = ({ version = 1 }) => {
  const {
    questionData,
    submitted,
    activeIndex,
    prevSlide,
    nextSlide,
    onUpdate,
    onSubmit,
  } = useApp(version)

  if (submitted) {
    return (
      <div className="loading">
        <h1>Preparing Results ...</h1>
        <img className="loading-image" src={spinner} alt="loading" />
      </div>
    )
  }

  return (
    <div className="swiper-main">
      <div className="swiper-pagination"></div>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {questionData.map((question, index) => (
            <div className="swiper-slide" key={index}>
              <Slide
                update={onUpdate}
                question={question}
                index={index}
                length={questionData.length}
              />
            </div>
          ))}
        </div>
      </div>
      {activeIndex > 0 && (
        <button className="button prev-button" onClick={prevSlide}>
          Previous
        </button>
      )}
      {activeIndex < 28 &&
        (questionData[activeIndex].type === 'text-slider' ||
          questionData[activeIndex].type === 'text-field' ||
          questionData[activeIndex].type === 'multiple-choice') && (
          <button className="button next-button" onClick={nextSlide}>
            Next
          </button>
        )}
      {activeIndex === 28 && (
        <button
          disabled={submitted}
          className="button results-button"
          onClick={onSubmit}
        >
          See My Results
        </button>
      )}
      {questionData[activeIndex].type === 'image' && (
        <button className="button image-button" onClick={nextSlide}>
          Got It
        </button>
      )}
    </div>
  )
}

export default Quiz
