import React from 'react'
import { useLocation } from 'react-router-dom'
// import Modal from 'react-modal'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_PatternFill from 'highcharts/modules/pattern-fill'
import Slider from 'react-slick'
import { useAssessment } from './hooks'

import AnimatedChart from '../data/MaternalWell.gif'
import CommunityImg from '../data/Community.png'
import TelehealthImg from '../data/Telehealth.jpg'
import ServiceImg from '../data/Service.png'
import ScreenShot1 from '../data/screenshot_1.jpg'
import ScreenShot2 from '../data/screenshot_2.jpg'
import ScreenShot3 from '../data/screenshot_3.jpg'
import ScreenShot4 from '../data/screenshot_4.jpg'
import reviewData from '../data/reviewData'
import spinner from '../loading.gif'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

HC_PatternFill(Highcharts)

const mwStageExplain = {
  Recognition:
    'awareness is the first step toward change! You may feel you have no control over pregnancy or postpartum issues but that’s not true. Being proactive can help alleviate or prevent issues during and after pregnancy.',
  Reflection:
    'you have acknowledged that there is work ahead of you, but you are ready to start on your path toward wellness.',
  Inclination:
    'you are on your path with plans to make changes to increase your wellness! ',
  Initiative:
    'you are well on your path of maternal wellness. You are aware of some challenges and how to be proactive.',
  Continuity:
    'are being proactive to stay ahead of unforseen challenges and promote wellbeing',
}

const Wrapper = ({
  children,
  hasNext = true,
  hasBack = true,
  onNext,
  onBack,
  id = '',
  label = 'Next',
}) => (
  <div className="result-page">
    <div className="result-content">
      {children}
      <div className="result-action">
        {hasBack && (
          <button id={id} className="button show prev-button" onClick={onBack}>
            Back
          </button>
        )}
        {hasNext && (
          <button id={id} className="button show" onClick={onNext}>
            {label}
          </button>
        )}
      </div>
    </div>
  </div>
)

const AssessmentResult = () => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const assessmentId = query.get('assessment_id')
  const type = query.get('type')
  const {
    loading,
    step,
    services,
    // bullets,
    mwStage,
    mwScore,
    // mwAnswer,
    // assessType,
    // mwPercent,
    postpartumOption,
    // respondentsPieOption,
    // showMore,
    onNext,
    onBack,
    onComplete,
    // onLearnMore,
    // onCloseLearnMore,
  } = useAssessment(assessmentId, type)

  const settings = {
    className: 'slider',
    dots: true,
    speed: 500,
    autoplaySpeed: 6000,
    autoplay: true,
    infinite: true,
    // centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  if (loading) {
    return (
      <div className="loading">
        <h1>Preparing Results ...</h1>
        <img className="loading-image" src={spinner} alt="loading" />
      </div>
    )
  }

  if (step === 0) {
    return (
      <Wrapper
        onNext={onNext}
        hasBack={false}
        id="analysis-score"
        // label="Learn More"
      >
        <p>
          <strong className="bold">
            You did it! You took the first step toward maternal wellness.
          </strong>
        </p>
        <p>
          Our assessment covered seven main areas of importance for wellness
          during pregnancy and postpartum. These include common challenges most
          mothers run into when pregnant and after childbirth.
        </p>
        <p>
          Based on your answers, your MaternalWell Awareness & Preparedness
          (MAP) score Is <strong>{mwScore}</strong>.
        </p>
        <p>
          {postpartumOption && (
            <HighchartsReact
              highcharts={Highcharts}
              options={postpartumOption}
            />
          )}
        </p>
      </Wrapper>
    )
  }

  if (step === 1) {
    return (
      <Wrapper
        onNext={onNext}
        onBack={onBack}
        id="analysis-score-2"
        // label="Learn More"
      >
        <p>The seven main topics covered in our assessment include:</p>
        <p>
          <ul>
            <li>Mental Health</li>
            <li>Breastfeeding/Lactation</li>
            <li>Infant Sleep Coaching</li>
            <li>Nutrition </li>
            <li>Physical Therapy</li>
            <li>Couples Wellness</li>
            <li>Lifestyle </li>
          </ul>
        </p>
        {services.length > 0 && (
          <p>
            Based on your assessment, we believe you would benefit the most from
            help with{' '}
            {services.map((item, index) => {
              if (index < services.length - 2) {
                return (
                  <>
                    <strong>
                      <i>{item},</i>
                    </strong>{' '}
                  </>
                )
              } else if (index === services.length - 2) {
                return (
                  <>
                    <strong>
                      <i>{item},</i>
                    </strong>
                    {' and '}
                  </>
                )
              }
              return (
                <strong>
                  <i>{item}.</i>
                </strong>
              )
            })}
          </p>
        )}
        {/* <p>Here are some TIPS especially for you!</p>
        <p>
          <ul>
            {bullets.map((bullet) => (
              <li>{bullet}</li>
            ))}
          </ul>
        </p> */}
        <p>
          Whatever your challenges or readiness,{' '}
          <strong>
            <i>you are not alone!</i>
          </strong>
        </p>
      </Wrapper>
    )
  }

  if (step === 2) {
    return (
      <Wrapper
        onNext={onNext}
        onBack={onBack}
        id="analysis-action"
        // label="Take Action"
      >
        <p>
          Your assessment is used to create a program that not only increases
          preparedness but also promotes and maintains behavioral changes to
          improve your overall wellness.
        </p>
        <p>
          <img className="animated-chart" src={ServiceImg} alt="Service" />
        </p>
        <p className="center">
          <strong>
            <i>
              “Knowledge is only potential power…action is power.” - Tony
              Robbins
            </i>
          </strong>
        </p>
      </Wrapper>
    )
  }

  // if (step === 2) {
  //   return (
  //     <Wrapper
  //       onNext={onNext}
  //       onBack={onBack}
  //       id="analysis-circle-graph"
  //       // label="We got you!"
  //     >
  //       <p>
  //         <strong>
  //           There are no <i>bad</i> scores
  //         </strong>
  //         {` `}- around {mwPercent}% of moms who took the assessment had a
  //         similar score.
  //       </p>
  //       <p>
  //         {respondentsPieOption && (
  //           <HighchartsReact
  //             highcharts={Highcharts}
  //             options={respondentsPieOption}
  //           />
  //         )}
  //       </p>
  //       <p className="center">
  //         Remember, whatever your challenges or readiness,{' '}
  //         <strong>
  //           <i>you are not alone!</i>
  //         </strong>
  //       </p>
  //     </Wrapper>
  //   )
  // }

  if (step === 3) {
    return (
      <Wrapper
        onNext={onNext}
        onBack={onBack}
        id="analysis-qualitative"
        // label="Keep Going!"
      >
        <p className="quote">
          <strong>The secret of getting ahead is getting started.</strong>
          <br />
          <strong>--Mark Twain</strong>
        </p>
        <p>
          The good news is <strong>{mwStageExplain[mwStage]}</strong>
        </p>
        <p>
          Your awareness, preparedness, and wellness can improve drastically
          over time with the proper guidance, support, and access to
          specialists.
        </p>
        <p>
          <img
            className="animated-chart"
            src={AnimatedChart}
            alt="Animated Chart"
          />
        </p>
      </Wrapper>
    )
  }

  if (step === 4) {
    return (
      <Wrapper
        onNext={onNext}
        onBack={onBack}
        id="analysis-sharma"
        // label="Take charge!"
      >
        <p className="quote">
          <p>
            <strong>
              Being proactive means you shape circumstances and they do not
              shape you.
            </strong>
          </p>
          <strong>
            <small>-Robin S. Sharma</small>
          </strong>
        </p>
        {/* <p>
          Awareness and proactiveness are essential to handle the challenges of
          pregnancy and new motherhood.
        </p> */}
        <p>
          <strong>
            <i>At MaternalWell, we've been through the struggles ourselves.</i>
          </strong>{' '}
          We were expected to suck it up, to figure it out by ourselves, or to
          suppress our feelings.{' '}
          <strong>
            <i>Our mission is to change that!</i>
          </strong>
        </p>
        <p>
          We thought through the{' '}
          <i>
            <u>many</u>
          </i>{' '}
          things we wish someone would have shared with us - whether it be
          advice, guidance, exercises, a space to vent, comfort in knowing
          someone has gone through the same issues, etc.
        </p>
        <p>Here's what our members have to say:</p>
        <p className="review-page">
          <Slider {...settings}>
            {reviewData.map((review, index) => (
              <div>
                <div className="result-review" key={review.name}>
                  <h4 className="review-title">{review.name}</h4>
                  <p className="review-content">"{review.text}"</p>
                </div>
              </div>
            ))}
          </Slider>
        </p>
        {/* <p style={{ textAlign: 'center' }}>
          <img
            className="specialist-chart"
            src={SpecialistImg}
            alt="Specialist"
          />
        </p> */}
      </Wrapper>
    )
  }

  if (step === 5) {
    return (
      <Wrapper
        onNext={onNext}
        onBack={onBack}
        id="analysis-knowledge"
        // label="Take Control"
      >
        <p className="quote">
          <strong>
            Most problems can be effectively dealt with before they are in full
            swing.
          </strong>
          <br />
          <strong>--Innocent Mwatsikesimbe</strong>
        </p>
        <p>
          <strong>
            <i>Did you know?</i>
          </strong>
        </p>
        <ul>
          <li>
            If you have risk factors for postpartum depression, starting
            cognitive behavioral therapy early can decrease your risk up to one
            year postpartum!
          </li>
          <li>
            Working with a lactation consultant doubles your chance to
            successfully breastfeed to at least 6 months!
          </li>
          <li>
            Studies have found that if you have high pregnancy anxiety, you are
            at 1.5 times greater risk of a pre-term birth.
          </li>
        </ul>
        <p>Don’t wait for issues! Let us help you!</p>
      </Wrapper>
    )
  }

  if (step === 6) {
    return (
      <Wrapper
        onNext={onNext}
        onBack={onBack}
        id="recommend1"
        // label="Learn More"
      >
        <p>
          Thank you for taking our assessment and joining the MaternalWell
          family with your free membership.
        </p>
        <p>
          The next step is to familiarize yourself with the portal so that all
          our experts and tools are available at your fingertips.
        </p>
      </Wrapper>
    )
  }

  if (step === 7) {
    return (
      <Wrapper onNext={onNext} onBack={onBack} id="recommend2">
        <p className="center">
          <strong>
            <i>
              "Anything is possible when you have the right people there to
              support you." - Misty Copeland.
            </i>
          </strong>
        </p>
        <p>
          With our membership, you get quick, convenient & affordable access to
          our licensed maternal health specialists…
          <i>in the comfort of your home.</i>
        </p>
        <p>
          <img
            className="animated-chart"
            src={TelehealthImg}
            alt="Telehealth"
          />
        </p>
      </Wrapper>
    )
  }

  if (step === 8) {
    return (
      <Wrapper onNext={onNext} onBack={onBack} id="recommend3">
        <p>
          <strong>Empower yourself with the MaternalWell Program</strong>
        </p>
        <p>Get bite-size daily doses of trusted information and motivation.</p>
        <p>Go through the interactive program at your own pace.</p>
        <p className="center screenshot-slider">
          <Slider {...settings} autoplaySpeed={3000}>
            <div>
              <img className="screenshot" src={ScreenShot1} alt="Screenshot" />
            </div>
            <div>
              <img className="screenshot" src={ScreenShot2} alt="Screenshot" />
            </div>
            <div>
              <img className="screenshot" src={ScreenShot3} alt="Screenshot" />
            </div>
            <div>
              <img className="screenshot" src={ScreenShot4} alt="Screenshot" />
            </div>
          </Slider>
        </p>
      </Wrapper>
    )
  }

  return (
    <Wrapper onNext={onComplete} onBack={onBack} label="Get Started" id="get_started">
      <p>
        <strong>Welcome to the MaternalWell Community!</strong>
      </p>
      <p>Peer group sessions with moms on a similar journey.</p>
      <p>
        The no judgement zone – share experiences, listen and learn, or just
        vent!
      </p>
      <p>
        <img className="animated-chart" src={CommunityImg} alt="Community" />
      </p>
      <p className="center">
        <strong>
          <i>
            Access the MaternalWell Program now to get started on your maternal
            wellness!
          </i>
        </strong>
      </p>
    </Wrapper>
  )
}

export default AssessmentResult
