import React from 'react'
import MultipleChoice from './multiple_choice'
import TextField from './text_field'
import TextFieldResult from './text_field_result'
import SlideImage from './image'
import TextSlider from './text_slider'

const Slide = ({ index, question, update }) => {
  const answers = () => {
    if (question.type === 'multiple-choice') {
      return (
        <MultipleChoice update={update} index={index} question={question} />
      )
    } else if (question.type === 'text-field') {
      return <TextField update={update} index={index} question={question} />
    } else if (question.type === 'image') {
      return <SlideImage question={question} />
    } else if (question.type === 'text-slider') {
      return <TextSlider update={update} index={index} question={question} />
    } else if (question.type === 'text-field-result') {
      return (
        <TextFieldResult update={update} index={index} question={question} />
      )
    }
  }

  return (
    <div>
      <h3>{question.title}</h3>
      <div>{answers()}</div>
    </div>
  )
}

export default Slide
